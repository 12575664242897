import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import members from '../../public/json/members.json'
import { Separator } from './Separator.jsx'

export const Team = () => {

    return <div className='section team' id="team-section">
        <div className="section-title">
            L'Équipe
        </div>
        <Container >
            <Row xs={1} lg={2} xl={2} md={1} sm={1} >
                {members.map((item, i) => <TeamMember key={i} {...item} />)}
            </Row>
        </Container>
    </div>

}

const TeamMember = props => {

    return <Col>
        <div className="member">
            <div className="team-member-img">
                <img src={props.photo} />
            </div>
            <div className="team-member-desc">
                <span>{props.name}</span><br />
                <span>{props.role}</span>
                <Separator width="100%" />
                <p>
                    {props.desc}
                </p>
            </div>
        </div>
    </Col>
}