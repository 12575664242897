
import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Box from '@material-ui/core/Box';

export const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: 'black',
    },
})((props) => <Tabs {...props} />);

export const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        padding: theme.spacing(3),
        '&:hover': {
            color: '#000',
            opacity: 1,
        },
        '&$selected': {
            color: '#000',
            fontWeight: theme.typography.fontWeightBold,
        },
        '&:focus': {
            color: '#000'
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    padding: {
        padding: theme.spacing(3),
    },
    demo1: {
        backgroundColor: theme.palette.background.paper,
    },
}));


export const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            className="donations"
            {...other}
        >
            {value === index && (
                <Box p={4}>
                    <p>{children}</p>
                </Box>
            )}
        </div>
    );
}