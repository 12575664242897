import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const Sponsors = () => {
	
	    // the Sponsor class. 
		class Sponsor {
		  constructor(name, imagePath, urlLink) {
		    this.name = name;
		    this.imagePath = imagePath;
		    this.urlLink = urlLink;
		  }
		};
		
		// create sponsors
		var sachetHeloue = new Sponsor("Sachet Heloue", "../../images/sponsors/heloue.png", "https://www.sachetheloue.org");
		var etriLabs = new Sponsor("EtriLabs", "../../images/sponsors/etrilabs.png", "https://etrilabs.com");
		var africaMondo = new Sponsor("Africa Mondo", "../../images/sponsors/mondo.png", "https://www.africamondo.com");
	    var ifemi = new Sponsor("IFEMI", "../../images/sponsors/ifemi.png", "http://association-ifemi.org/");

	    // Put Sponsor objects in an array
		let sponsorArr = [];
		sponsorArr.push(sachetHeloue, etriLabs, africaMondo, ifemi);
	
    
    return <div className="section sponsors" id="sponsors-section">
        <div className="section-title">Nos Partenaires</div>
        <Container fluid>
            <Row className="justify-content-md-center">
                {[...sponsorArr].map((item, i) => 
                  <Col className="sub-sponsor" xs={12} sm={12} md={4} lg={4} xl={4}>
                     
                    <a href={item.urlLink} target="_blank">
	                    <img src={item.imagePath} title={item.name} alt={item.name} />
	                </a>
                  </Col>)}
            </Row>
        </Container>
    </div>
}