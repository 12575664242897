import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const SmallAbout = () => {

    return <div className="section" id="the-about-section">
        <div className="section-title">
            La diaspora se mobilise
        </div>
        <Container >
            <Row className="justify-content-md-center">
                <Col xs={12} lg={8} md={10} sm={12} className="pl-5 pr-5">
                    <p className="small-about ">
                        ONVIT-20 est une initiative de jeunes béninois de la diaspora pour aider les populations vulnérables du Bénin face à la crise sanitaire actuelle. Notre objectif est de permettre à des familles résidant dans les communes cernées par le cordon sanitaire, de continuer à se nourrir et se protéger durant cette pandémie dont personne ne connaît jusque-là l'issue.
                    </p>
                </Col>
            </Row>
        </Container>
    </div>

}