
import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const SocialMedia = () => {

    return <div id="social-media-section">
        <div className="section-title-small">Retrouvez-nous sur les réseaux sociaux</div>
        <div className="social-links">
            <a href="https://www.instagram.com/onvit_20/?igshid=owxq8haezkxj" target="_blank">
                <img src="https://img.icons8.com/ios-filled/50/000000/instagram.png" />
            </a>
            <a href="https://www.facebook.com/Onvit-20-Tous-ensemble-pour-vaincre-le-Covid-19-au-B%C3%A9nin-106207114391514/" target="_blank">
                <img src="https://img.icons8.com/ios-filled/50/000000/facebook-circled.png" />
            </a>
            <a href="https://twitter.com/20Onvit" target="_blank">
                <img src="https://img.icons8.com/ios-filled/50/000000/twitter-squared.png" />
            </a>
        </div>
    </div>
}