import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const DonationProgressBar = () => {

    return <Container>
        <Row className="justify-content-md-center">
            <Col xs={12} lg={12} md={12} sm={12}>
                <div className="donation-call-to-action-amount">
                    <span>60 000 euros collectés</span> <span>sur 100 000</span>
                </div>
                <div className='donation-progress-bar'>
                    <div className="donation-reached" style={{ width: "60%" }}>
                        60%
                    </div>
                    <div className="donation-goal" style={{ width: "40%" }} />
                </div>
            </Col>
        </Row>
    </Container>

}