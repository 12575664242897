import React from 'react';

export const Copyright = () => {

    const current_date = new Date()
    const year = current_date.getFullYear()

    return <div className='copyright'>
       © {year} ONVIT-20. Tous droits réservés.
    </div>

}