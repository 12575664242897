import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Separator } from './Separator.jsx'

export const SpecialThanks = () => {

    return <div className="special-thanks-background">
        <div className='section special-thanks'>
            <div className="section-title-small">Spéciaux Remerciements</div>
            <Separator width="100px" />
            <Container fluid>
                <Row className="justify-content-md-center">
                    <Col xs={12} lg={6} md={10} sm={12} className="pt-5">
                        <p >
                            L'équipe ONVIT-20 va bien au delà des 6 visages ci-dessus. 
                            Une armée de volontaires font des travaux de fourmis et d'abeilles 
                            afin que nos efforts aboutissent avec le maximum d'efficacité possible.
                        </p>
                        <p>
                            Nous tenons à remercier nos super bénévoles: Brayan HOUNKPATIN, Grâce AMALIN, Enrif MALADÉ,
                            Josephiane WANKPO pour une travail remarquable.
                        </p>
                        <p>Nous n'oublions pas
                            Aïka MATHELIER, Marie-Caroline KOTTIN HOUENON, Romance HOUNKPATIN, Karen LAMMOGLIA,
                            Achraf Alassane KEMOKO,
                            Yves-Axel ALAPINI,
                            Farid YESSOUFOU,
                            Stephane KOUASSI,
                            Lionel B. MORY,
                            Leyla SIDI,
                            Nasser MAMA,
                            Shana HOEKE,
                            Alabi FARATI, Credo HESSOU, Yannick FOLLY, Lucas BOKO, Linda AGBO
                            pour leur soutien spécial dans le cadre de nos activités.
                        </p>
                        <p>
                            Nos ONG partenaires sur le terrain ont joué un rôle capital en nous donnant accès à leurs
                            ressources humaines ainsi qu'à leur expertise. Nous tenons à remercier Sandra IDOSSOU de
                            SACHET HELOUE et ses collaborateurs, Murielle ANATOHON. de ETRILABS et ses collaborateurs, Mélina
                            SEYMOUR de AFRICA MONDO et ses collaborateurs, Carlio K. GBENOU de IFEMI et ses
                            collaborateurs.
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    </div>
}