import React from 'react';

export const Separator = props => {

    return <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <div
            style={{ 
                height: "1px",
                width: props.width || "60px",
                backgroundColor: "" + (props.color || "#eee" ) + ""
            }}
        />
    </div>

}