
import React from 'react';
import { Pie } from 'react-chartjs-2';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';

import {
    AntTabs,
    AntTab,
    TabPanel,
    useStyles,

} from './MaterialUI.jsx';

import donations from '../../public/json/donations.json'

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}


export const DonationsStats = () => {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="section donateStat">
            <div className="section-title">
                Vos dons en actions<br />
            </div>
            <Container fluid>
                <Row className="justify-content-md-center">
                    <Col md={10} sm={12} xs={12} xl={9} >

                        <div className={classes.root}>
                            <div className={classes.demo1}>
                                <AppBar
                                    position="static"
                                    color="white"
                                    elevation={0}
                                >
                                    <AntTabs
                                        value={value}
                                        onChange={handleChange}
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="scrollable auto tabs example"
                                    >
                                        <AntTab
                                            label={'Impact total'}
                                            {...a11yProps(0)}
                                        />
                                        {
                                            donations.map((donation, i) => (
                                                <AntTab
                                                    key={i + 1}
                                                    label={donation.category}
                                                    {...a11yProps(i + 1)}
                                                />
                                            ))
                                        }

                                    </AntTabs>
                                </AppBar>
                                <TabPanel value={value} index={0} >
                                    <ImpactTotal />
                                </TabPanel>
                                {
                                    donations.map((donation, i) => (
                                        <TabPanel value={value} index={i + 1} key={i + 1}>
                                            <DonationTabContent donation={donation} />
                                        </TabPanel>
                                    ))
                                }
                            </div>
                        </div>

                    </Col>
                </Row>
            </Container>
        </div>
    );

}

const ImpactTotal = () => {

    const colors = [
        '#3B8A25',
        '#FFC705',
        '#E81300',
        '#B29D64',
        '#B7B139',
        '#038C8C',
    ]

    const legends = donations.map((item, i) => {
        return <span style={{ color: colors[i], padding: '0 20px 0 0', fontSize: '.9em', fontWeight: 'bold' }}>.{item.category}</span>
    })

    const data = {
        labels: donations.map(item => item.category),
        datasets: [{
            data: donations.map(item => item.totalCost),
            backgroundColor: colors,
        }]
    };

    return (
        <Row xs={1}>
            <Col xl={4} xs={12}>
                <Row className="justify-content-md-center" >
                    <div style={{ maxWidth: '300px' }}>
                        <Pie
                            data={data}
                            legend={{ display: false }}
                            height={200}
                        />
                        <br /><br />
                    </div>
                </Row>
            </Col>
            <Col xl={8} xs={12}>
                <div style={{ padding: '0px 30px' }}>
                    <p>{legends}</p>
                    <br />
                    <p>
                        Grâce à vous nous avons eu un impact direct sur 100 personnes étant franchement dans le besoin créé par les fermetures liées
                        au Covid, et nous avons eu un impact indirect en collaborant avec des entreprises locales fournissant 25 emplois.
                    </p>
                    <br />
                    <h2>Merci!</h2>
                </div>
            </Col>
        </Row >
    )
}

const DonationTabContent = props => {

    const { donation } = props

    let total = donation.partner.reduce(function (prev, cur) {
        return prev + cur.donation.donationCost;
    }, 0);

    return (
        <>
            <Row>
                <Col>
                    <p style={{ textAlign: 'center' }}>
                        {donation.desc}
                    </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div style={{ textAlign: 'center', color: '#3B8A25' }}>
                        <span className="totalsCount">{donation.totalQuantity}</span>
                        <br />
                        <span className="totalsTile">{donation.category}</span>
                    </div>
                </Col>
                <Col>
                    <div style={{ textAlign: 'center', color: '#FFC705' }}>
                        <span className="totalsCount">{donation.totalPersonImpacted}</span>
                        <br />
                        <span className="totalsTile">personnes</span>
                    </div>
                </Col>
                <Col>
                    <div style={{ textAlign: 'center', color: '#E81300' }}>
                        <span className="totalsCount">{total}</span>
                        <br />
                        <span className="totalsTile">euros</span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Box pt={5}>
                        <div >
                            <Row>
                                <Col xl={8} xs={6}>
                                    <span style={{ fontWeight: 'bold', paddingLeft: '10px' }}>Intermédiaires</span>
                                </Col>
                                <Col xl={4} xs={6}>
                                    <div style={{ fontWeight: 'bold' }}>
                                        Dons
                                    </div>
                                </Col>
                            </Row>
                            <hr style={{ margin: '20px 0' }} />
                        </div>
                        {
                            donation.partner && donation.partner.map((partner, i) => (
                                <div >
                                    <Row>
                                        <Col xl={8} xs={6}>
                                            <span style={{ paddingLeft: '10px' }}>{partner.name}</span>
                                        </Col>
                                        <Col xl={4} xs={6}>
                                            <div >
                                                {partner.donation.donationQty} (&euro; {partner.donation.donationCost})
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr style={{ margin: '20px 0' }} />
                                </div>
                            ))
                        }
                    </Box>
                </Col>
            </Row>
        </>
    )
}