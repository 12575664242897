import React from 'react';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { BannerDonationProgress } from './BannerDonationProgress.jsx'

export const Banner = () => {

    const now = 60;

    return <div className="banner">
        <Container>
            <Row>
                <Col xs={12} lg={12} md={12} sm={12}>
                    <div className="banner-content s">
                        <div className="iframe-container text-center mt-5">
                            <iframe width="640" height="360" src="https://www.youtube.com/embed/dEXZZWyQa3c?rel=0&modestbranding=1"
                                    frameBorder="0" modestbranding="1"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        <BannerDonationProgress />
        <div className="banner-photo-credit d-none">
            Photo Credit <a href="https://unsplash.com/@hcmorr" target="blank">Hanna Morris</a>
        </div>
    </div>

}