import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const Process = () => {

    return <div className="process-background">
        <div className="section process">
            <div className="section-title">
                La démarche ONVIT-20
            </div>
            <Container fluid>
                <Row className="justify-content-md-center pt-5">
                    <Col xl={10} md={12} sm={12} xs={12}>
                        <Row xs={1} lg={4} xl={4} md={2} sm={2} className="justify-content-md-center">
                            <Col className="pl-5 pr-5">
                                <div className="process-image">
                                    <img src="../../images/process/research.png" />
                                </div>
                                <h4 className="process-title">MOBILISER</h4>
                                <p>
En adhérant à notre vision, la diaspora contribue de tout coeur à la cause. Centraliser les dons pour réduire les dépenses annexes permet de fournir au Bénin le maximum d'aide possible.
                        </p>
                            </Col>
                            <Col className="p-5pl-5 pr-5">
                                <div className="process-image">
                                    <img src="../../images/process/respect.png" />
                                </div>
                                <h4 className="process-title">COLLABORER</h4>
                                <p>
Sensible aux initiatives prises par la jeunesse sur le territoire national, la diaspora béninoise collabore avec ceux pouvant le plus amplifier ses actions sur le terrain.
                        </p>
                            </Col>
                            <Col className="p-5pl-5 pr-5">
                                <div className="process-image">
                                    <img src="../../images/process/teacher.png" />
                                </div>
                                <h4 className="process-title">SUPERVISER</h4>
                                <p>
Collecter des fonds est assurément une chose louable. Suivre l'acheminement des dons jusqu'à leur distribution aux destinataires finaux, c'est encore mieux.
                        </p>
                            </Col>
                            <Col className="p-5pl-5 pr-5">
                                <div className="process-image">
                                    <img src="../../images/process/report.png" />
                                </div>
                                <h4 className="process-title">RENDRE COMPTE</h4>
                                <p>
Il nous tient très à coeur que chaque donateur et volontaire de près ou de loin, soit fier de sa contribution sur le terrain. Pour cela, nous rendrons compte sur le site web de l'avancement des distributions.
                        </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Container>
        </div>
    </div >

}