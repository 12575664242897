import React from 'react';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

export const Header = () => {

    return <div className="header">
        <Container fluid>
            <div className="no-gutter">
                <Row xs={2} lg={2} xl={2} md={2} sm={2}>
                    <Col xl={2} xs={3} sm={3} md={3} lg={3} >
                        <div className="logo">
                            <img src="../../images/Logo-1-white.png" />
                        </div>
                    </Col>
                    <Col xl={10} xs={9} sm={9} md={9} lg={9} >
                        <div className="header-link">
                            <a href="#the-about-section">À propos</a>
                            <a href="#team-section">L'Équipe</a>
                            <a href="#sponsors-section">Partenaires</a>
                            <a href="https://ca.gofundme.com/f/covid19fundraising-for-benin" style={{ padding: "5px 10px", border: "1px solid white"}} target="blank">je fais un don</a>
                            <a href="https://onvit20.namionwits.com/EN" className="english-translate">EN</a>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    </div>


}