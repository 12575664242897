import React, { useState, useCallback } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Gallery from 'react-photo-gallery';
import {photos}  from './imageList.jsx';
import Carousel, { Modal, ModalGateway } from "react-images";
// xs={1} lg={2} xl={2} md={1} sm={1}


export const Galleria = () => {

    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, {photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);
    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };


    return (
        <div className="section gallery" id="gallery-section">
            <div className="section-title">
                Quelques images
            </div>
            <div className="galll-style">
                <Gallery photos={photos} onClick={openLightbox} />
                <ModalGateway>
                    {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                            <Carousel
                                currentIndex={currentImage}
                                views={photos.map(x => ({
                                    ...x,
                                    srcset: x.srcSet,
                                    caption: x.title
                                }))}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </div>
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs={12} lg={12} md={10} sm={12}>
                        <div className='button-seeMore'>
                            <a href="#" target="_blank" className="d-none">
                                Voir plus d'images
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>

        </div>
    );
}

