import React from 'react';
import ReactDOM from 'react-dom';

import '../../public/styles.css';

import { Banner } from './Banner.jsx'
import { DonationsStats } from './DonationsStats.jsx'
import { About } from './About.jsx'
import { SmallAbout } from './SmallAbout.jsx'
import { Copyright } from './Copyright.jsx'
import { Header } from './Header.jsx'
import { Team } from './Team.jsx'
import { Sponsors } from './Sponsors.jsx'
import { SocialMedia } from './SocialMedia.jsx'
import { Process } from './Process.jsx'
import { SpecialThanks } from './SpecialThanks.jsx'
import { GoFundMeWidget } from './GoFundMeWidget.jsx'
import { Galleria } from './Galleria.jsx'


export const Wrapper = () => {

    return <div >
        <Header />
        <Banner />
        <SmallAbout />
        <GoFundMeWidget />
        <Process />
        <DonationsStats />
        <Galleria />
        <Team />
        <SpecialThanks />
        <Sponsors />
        <SocialMedia />
        <Copyright />
        
    </div>
}