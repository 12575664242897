import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import { Wrapper } from './Components/Wrapper.jsx'

ReactDOM.render(
    <Wrapper />,
    document.getElementById("root")
);