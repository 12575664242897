import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { DonationProgressBar } from './DonationProgressBar.jsx';

export const BannerDonationProgress = () => {

    return <div className="donation-call-to-action">
        <Container>
            <Row className="justify-content-md-center">
                <Col xs={12} lg={12} md={12} sm={12}>
                    <div className="banner-content">
                        <div className="iframe-container">

                        </div>

                        <div className="d-none">
                            <p>Tous ensemble pour vaincre<br />le Covid-19 au Bénin.</p>
                        </div>
                    </div>                
                    <div className="donation-call-to-action-thanks d-none" >
                       Aucune contribution n'est insignifiante.<br/>Avec 1 Euro, vous aidez une personne de plus à avoir de quoi rester chez elle ou à avoir un 
                       masque de plus à sa disposition.
                    </div>
                </Col>
            </Row>
        </Container>
        {/* <DonationProgressBar /> */}
        <Container>
            <Row className="justify-content-md-center">
                <Col xs={12} lg={12} md={10} sm={12}>
                    <div className='button-donate d-none'>
                        <a href="#" >

                        </a>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
}