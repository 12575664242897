import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const About = () => {

    return <div className='about section' id="call-to-action-text">
        <Container >
            <div className="section-title">À propos de ONVIT-20</div>
            <Row className="justify-content-md-center">
                <Col xs={12} lg={12} md={10} sm={12} className="pl-5 pr-5">
                    <p>
                        L’initiative germe au sein de quelques maillons de la splendide chaîne que constitue
                        la diaspora béninoise. Donner du renfort au personnel de santé, équiper nos compatriotes
                        en supportant les initiatives de sensibilisation, en fournissant du matériel de protection
                        et des vivres pour ceux qui en ont le plus besoin.
                    </p>
                    <p>
                        L’initiative ONVIT-20 est pilotée par une équipe principale de 6 membres, supportée par un
                        grand nombre de volontaires remarquables, contribuant en talents et de bien d’autres manières.
                    </p>
                    <p>
                        L’équipe ONVIT-20 vise la pertinence dans l’action et la transparence
                        dans les rapports. Notre équipe établit par un processus rigoureux, des
                        partenariats avec des ONG ou individus sur place au Bénin pour collecter les besoins
                        et assurer la distribution des items nécessaires. Des rapports d’activités en temps
                        réel sont prévus pour le suivi de tous.
                    </p>
                    <p>
                        ONVIT-20, C’est l’action de tous, et des béninois dans le cœur, et des béninois dans le sang.
                    </p>
                </Col>
            </Row>
        </Container>
    </div>

}